<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Knowledge Base Categories')">
        <block-tools>
          <li>
            <nk-button type="primary" v-on:click="[modalIsOpen=true, modalTitle='Add Knowledge Base Category', clearForm()]">
              <nio-icon icon="ni-plus"></nio-icon>
              {{ $t('Add Category') }}
            </nk-button>
          </li>
        </block-tools>
      </block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <div class="form-inline flex-nowrap gx-3">
                    <div class="form-wrap w-150px">
                      <select class="form-select form-select-md form-control" v-model="kbCategories.order">
                        <option value="desc">{{ $t('Newest Categories') }}</option>
                        <option value="asc">{{ $t('Oldest categories') }}</option>
                      </select>
                    </div>
                  </div>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="advisors_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}"
                        >
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search')+' '+$t('Knowledge Category')"
                  :is-active="searchBarActive"
                  :search-term="search"
                  @do-search="searchCats"
                  @close-search="[searchBarActive=false, search='']"
              ></card-search>
            </div>
            <div class="d-flex py-5 justify-center" v-if="loadingData">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <special-table>
                  <table-row is-heading>
                    <table-col>
                      <span class="sub-text">ID</span>
                    </table-col>
                    <table-col break-point="lg">{{ $t('Icon') }}</table-col>
                    <table-col>
                      <span class="sub-text">{{ $t('Name') }}</span>
                    </table-col>
                    <table-col break-point="md">{{ $t('Slug') }}</table-col>
                    <table-col break-point="lg">{{ $t('Description') }}</table-col>
                    <table-col break-point="md">{{ $t('Created At') }}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="(kb_cat, index) in kbCategories.data" :key="index">
                    <table-col>{{ kb_cat.id }}</table-col>
                    <table-col break-point="lg" v-html="kb_cat.icon"></table-col>
                    <table-col> {{ kb_cat.name }}</table-col>
                    <table-col break-point="md"> {{ kb_cat.slug }}</table-col>
                    <table-col break-point="lg" v-html="kb_cat.description"></table-col>
                    <table-col break-point="md">{{ kb_cat.created_at }}</table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-edit" :text="$t('Edit')" @click="updateKbCategory(kb_cat)"></list-item>
                            <list-item icon="ni-trash" :text="$t('Delete')" @click="deleteKbCategory(kb_cat.id)"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>
                </special-table>
              </div>
              <div class="card-inner" v-if="kbCategories.totalRecords > kbCategories.perPage">
                <pagination
                    v-model="kbCategories.page"
                    :records="kbCategories.totalRecords"
                    :per-page="kbCategories.perPage"
                    @paginate="paginationCallback"
                    :options="{chunk: kbCategories.perPage, texts:{count: '||'}}"
                />
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
    <ion-modal
        :is-open="modalIsOpen"
        @didDismiss="modalIsOpen=false"
        :css-class="isDesktop ? 'modal-web': ''"
    >
      <i-modal :title="$t(modalTitle)" @modal-close="modalIsOpen=false">
        <row class="g-3 align-center mb-3 mb-md-0">
          <column md="6">
            <label class="form-label" for="cat_name">{{ $t('Name') }} *</label>
            <form-note>{{ $t('The name that appears on your site.') }}</form-note>
            <b-input type="text" id="cat_name" v-model="kbCategory.name"/>
          </column>
          <column md="6">
            <label class="form-label" for="cat_slug">{{ $t('Slug') }} *</label>
            <form-note>{{ $t('The URL friendly version of name.') }}</form-note>
            <b-input type="text" id="cat_slug" v-model="kbCategory.slug"/>
          </column>
        </row>

        <row class="g-3 align-center mb-3 mb-md-0">
          <column md="12">
            <label class="form-label" for="cat_icon">{{ $t('Icon') }}</label>
            <form-note>{{ $t('SVG icon (SVG code)') }}</form-note>
            <textarea id="cat_icon" class="form-control" rows="5" v-model="kbCategory.icon"></textarea>
          </column>
        </row>

        <row class="g-3 align-center mb-3 mb-md-0">
          <column md="12" class="ck-cat-des">
            <label class="form-label" for="cat_desc">{{ $t('Description') }}</label>
            <ckeditor class="border-light border bg-white px-3" v-model="kbCategory.description" :editor="ClassicEditor" :config="classicEditorConfig"></ckeditor>
          </column>
        </row>

        <template v-slot:footer class="p-5">
          <nk-button type="primary" class="mr-2" @click="addOrUpdateKbCat">{{$t('Add')}}</nk-button>
          <a href="javascript:;" class="link link-light" v-on:click="modalIsOpen=false">{{$t('Cancel')}}</a>
        </template>
      </i-modal>
    </ion-modal>
  </page-template>
</template>

<script>
import {
    IonModal,
    isPlatform,
    IonSpinner, loadingController
} from '@ionic/vue';
import FormNote from "@core/components/bp-form/components/FormNote";
import {
    MainBlock,
    BlockHead,
    BlockContent,
    BlockTools,
    Column,
    Row
} from "@/@core/layouts";
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import {BtnToolbar, BtnToolbarToggleWrap} from "@core/components/btn-toolbar"
import "bootstrap"
import {BInput} from "@core/components/bp-form";
import Pagination from "v-pagination-3"
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import ListItem from "@core/components/dropdown-list/ListItem";
import {DropDownList} from "@core/components";

import {useRouter} from "vue-router"
import {useI18n} from "vue-i18n";
import IModal from "@core/components/ion-modal/IModal";
import {reactive, ref, watch, onMounted, computed} from "vue";
import axios from "@/libs/axios";
import useIonComponents from "@core/IonComponents";
import useKb from "@core/comp-functions/knowledgeBase"
import CKEditor from "@ckeditor/ckeditor5-vue"
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor"
import {classicEditorConfig} from '@/libs/editor-config'
import PageTemplate from "@core/layouts/page/PageTemplate";
export default {
    name: "index",
    components: {
      PageTemplate,
        BlockTools,
        IonSpinner,
        BlockContent,
        ListItem,
        SpecialTable,
        Pagination,
        TableCol,
        TableRow,
        BtnToolbar,
        BtnToolbarToggleWrap,
        Card,
        CardHeader,
        CardSearch,
        CardTools,
        Column,
        Row,
        BlockHead,
        BInput,
        DropDownList,
        IonModal,
        IModal,
        MainBlock,
        FormNote,
        ckeditor: CKEditor.component,
    },
    setup() {
        const isDesktop = isPlatform('desktop')
        const router = useRouter()
        const {deleteKbCat} = useKb()
        const modalIsOpen = ref(false)
        const deleteModal = ref(false)
        let searchBarActive = ref(false)
        let loadingData = ref(false)
        let search = ref('');
        const {t} = useI18n()
        const {IonSimpleAlert, IonToast} = useIonComponents()
        let modalTitle = ref('')
        let message = ref('')
        let kbCategory = reactive({
            id: 0,
            description: '',
            icon: '',
            name: '',
            slug: '',
        })
        let kbCategories = reactive({
            data: [],
            page: 1,
            order: 'desc',
            perPage: 5,
            totalRecords: 0,
        })
        let spinners = reactive({
            kbCategory: false,
        })
        const addOrUpdateKbCat = async () => {
            let loader = await loadingController.create({message: t('Please wait...')})
            await loader.present()
            axios.put(`/api/admin/knowledge_base/cat/add`, kbCategory)
                .then(rs => {
                    loader.dismiss()
                    if (!rs.data.success) {
                        IonSimpleAlert('Error', rs.data.message, t('OK'))
                    } else {
                        modalIsOpen.value = false
                        if (kbCategory.id != 0) message = 'Congratulation! Knowledge Base Category Updated Successfully!'
                        else message = 'Congratulation! Knowledge Base Category Created Successfully!'
                        IonToast({
                            header: t('Success'),
                            message: t(message),
                            position: 'top',
                            color: 'primary',
                            duration: 3000
                        });
                        loadCats();
                    }
                })
                .catch(er => {
                    IonSimpleAlert('Error', er.response ? er.response.status + ' ' + er.response.statusText : 'errors.general_error', 'OK')
                })
                .then(() => loader.dismiss())
        }
        let cancelToken = false

        const loadCats = async () => {
            loadingData.value = true
            if(false !== cancelToken){
              cancelToken()
              cancelToken = false
            }
            axios.get(apiUrl.value,{
              cancelToken: new axios.CancelToken( c => {
                cancelToken = c
              })
            }).then((res) => {
                    kbCategories.data = res.data.data.data
                    kbCategories.totalRecords = res.data.data.total
                    loadingData.value = false
            }).catch((er) => {
              if(er.toString() !== 'Cancel'){
                IonSimpleAlert('Error', er.response ? er.response.status + ' ' + er.response.statusText : 'errors.general_error', 'OK')
              }
            }).then(() => {
              loadingData.value = false
              cancelToken = false
            })
        }

        const updateKbCategory = (kb_cat) => {
            Object.assign(kbCategory, kb_cat)
            modalTitle.value = "Update Knowledge Base Category"
            modalIsOpen.value = true
        }

        const deleteKbCategory = async(id) => {
            await deleteKbCat(`/api/admin/knowledge_base/cat/delete/${id}`,'Knowledge Base Category')
            await loadCats()
        }

        const clearForm = () => {
            Object.assign(kbCategory, {
                id: 0,
                description: '',
                icon: '',
                name: '',
                slug: '',
            })
        }

        const apiUrl = computed(() => {
            return `/api/admin/knowledge_base/cat/list?page=${kbCategories.page}&per_page=${kbCategories.perPage}&order=${kbCategories.order}&search=${search.value}`;
        })

        watch(apiUrl, () => {
            loadCats();
        })
        watch(
            () => kbCategory.name,
            (val) => {
                kbCategory.slug = val.toLowerCase()
                    .replace(/[^\w ]+/g, '')
                    .replace(/ +/g, '-');
            },
            { deep: true }
        )

        const searchCats = (val) => {
            search.value = val
        }

        const paginationCallback = async (val) => {
            kbCategories.page = val
        }

        onMounted(() => {
            loadCats();
        })

        return {
            loadCats,
            isDesktop,
            loadingData,
            kbCategory,
            kbCategories,
            modalTitle,
            addOrUpdateKbCat,

            searchBarActive,
            modalIsOpen,
            deleteModal,
            apiUrl,
            spinners,
            router,
            search,
            searchCats,
            paginationCallback,
            updateKbCategory,
            deleteKbCategory,
            clearForm,
            ClassicEditor,
            classicEditorConfig
        }
    }
}
</script>

<style>
.ck-cat-des .ck-reset .ck.ck-editor__main .ck-editor__editable_inline {
    min-height: 150px;
}
</style>
